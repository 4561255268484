<template>
  <div>
    <ListingLayout
      :sub-screen="1"
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="path"
      :bread-crumbs="false"
      no-filters
      :data-table-key="0"
      telematic-massage-rows
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <!-- :date-custom-options="false" -->
      <!-- Custom Status Column -->
      <template v-slot:location="{ item }">
        <span> {{ item.latitude }}, {{ item.longitude }} </span>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <div>
          <date-time
            v-model="customFilter.date"
            type="datetime"
            placeholder="Select Date Range"
            :disabled="isPlaying"
            range
            :clearable="false"
            confirm
            confirm-text="Apply"
            @confirm="fetchData"
          />
        </div>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <!-- Play/Pause Button Section -->
        <div
          v-if="!isLoading"
          class="historyplaybacktop gap5"
        >
          <!-- Speed Slider -->
          <!-- <div class="slider-container">
            <label
              for="speed-slider"
              class="slider-label"
            >
              Speed:
            </label>
            <v-slider
              id="speed-slider"
              v-model="speed"
              :max="intervalTimeOpt?.length"
              min="1"
              step="1"
              thumb-label
              color="primary"
              class="custom-slider"
            />
          </div> -->
        </div>
      </template>
      <!-- Action Modals -->

      <!-- Section -->
      <template #description>
        <v-row class="py-0 insideOveviewTabDetails px-0 mx-0 my-0">
          <v-col
            cols="12"
            class="px-0 py-0"
          >
            <v-card
              :loading="isLoading"
              class="leftOverviewCard py-0 px-0"
            >
              <!-- {{ path }} \\ -->

              <div class="mapWrapper py-4 px-4">
                <v-skeleton-loader
                  v-if="isLoading || !isMap"
                  type="image"
                  style="flex: 1"
                />
                <trip-play-back
                  v-else
                  ref="tripPlayBack"
                  :trips-data="path"
                  direction-arrow-color
                  :popup-options="[
                    'latitude',
                    'longitude',
                    'speed',
                    'date_time',
                  ]"
                  :vehicle-status="vehicle_status"
                  :vehicle-body-type="vehicle_model_body_type"
                  :event-data="eventData"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <!-- Section -->
    </ListingLayout>
  </div>
</template>

<script>
import service from "@/helpers/py-service";

import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { debounce } from "lodash";
import moment from "moment";

export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    TripPlayBack: () =>
      import("@/components/sections/VueLeafLetMap/LeafMap.vue"),

    DateTime: DatePicker,
  },
  mixins: [ListingLayoutMixins],
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    selectedTab: {
      type: String,
      default() {
        return "";
      },
    },
    optionFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isMap: true,
      dateRange: [], // The selected date range
      filters: {},
      subFilters: {},
      customFilter: {
        date: [],
      },
      //
      lastVehicleID: null,
      //
      // for Trip play back
      isPlaying: false,
      speed: 4,
      count: 1,
      intervalId: null,
      intervalTimeOpt: [3300, 3000, 2500, 2000, 1500, 1000, 800, 600, 400, 200],
      intervalValue: 65656,
      //

      isLoading: false,
      eventData: [],

      streamData: [],
      path: [],
      vehicle_status: "",
      vehicle_model_type: "",
      vehicle_model_body_type: "",
      //
      popup: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      list: "telematicDetails/getTelematicDetailsList",
      //
      tenantList: "tenantsManagement/getTenantOptions",
    }),
    _headers() {
      let headerArr = [
        { text: "Date/Time", value: "date_time", sortable: false },
        { text: "State", value: "state", sortable: false },
        { text: this.$t("speed"), value: "speed", sortable: false },
        {
          text: this.$t("duration"),
          value: "formatted_duration",
          sortable: false,
        },
        { text: this.$t("start_time"), value: "start_time", sortable: false },
        { text: this.$t("end_time"), value: "end_time", sortable: false },
        { text: "Location", value: "location", sortable: false },
      ];

      return headerArr;
    },
    meta() {
      return {
        page: 1,
        itemsPerPage: this.path.length,
        lastPage: 1,
        totalItems: 10000,
      };
    },
  },
  watch: {
    "optionFilters.vehicle_id": {
      handler: async function () {
        // log("heee", v, this.name == this.selectedTab);
        this.clearData();
        // if (this.name === this.selectedTab) {
        //   if (v) {
        //     log("vhicle change ");
        //     this.fetchData();
        //   }
        // }
      },
    },
    selectedTab: {
      handler: async function (v) {
        this.clearData();
        if (v === this.name) {
          const vd = this.optionFilters.vehicle_id;

          if (vd != this.lastVehicleID) {
            this.fetchData();
          }
        }
      },
    },

    options() {
      this.fetchData();
    },
  },
  async mounted() {},
  beforeDestroy() {},

  methods: {
    format(value) {
      if (!value) return "N/A";
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    fetchData: debounce(function () {
      if (this.customFilter.date.length > 0) {
        this.lastVehicleID = this.optionFilters.vehicle_id;

        this.fetchStreamData();
        // this.tripStop(); // clear interval and fetch fresh data
        // this.fetchEventData();
      }
    }, 1500),

    async fetchStreamData() {
      const date = [
        this.format(this.customFilter.date[0]),
        this.format(this.customFilter.date[1]),
      ];

      const params = {
        date,
        ...this.optionFilters,
      };

      this.streamData = [];
      this.path = [];
      this.isLoading = true;
      await service
        .get(`vehicle/telematic-details-historical-playback`, params)
        .then((res) => {
          if (res.message) {
            this.showError(res);
            this.isLoading = false;
            return 0;
          }
          const rows = res.trim().split("\n");
          rows.forEach((el, i) => {
            const row = JSON.parse(el);

            if (i === 0) {
              this.vehicle_model_body_type = row?.vehicle_model_body_type;
              this.vehicle_status = row?.vehicle_status;
              this.vehicle_model_type = row?.vehicle_model_type;
            }

            if (row?.data.length > 0) {
              row.data.forEach((dt) => {
                const params = {
                  state: dt.state,
                  latitude: dt.position_latitude,
                  longitude: dt.position_longitude,
                  speed: dt.speed,
                  over_speed: dt.over_speed,
                  duration: dt.formatted_duration,
                  formatted_duration: dt.formatted_duration,
                  start_time: dt.start_time_format,
                  end_time: dt.end_time_format,
                  date_time: dt.timestamp_format,
                };
                if (params.state) {
                  this.streamData.push(params);
                  // if (dt.state == "Driving") {
                  this.path.push(params);
                  // }
                }
              });
            }
          });
          this.isLoading = false;
          // this.fetchEventData();
        });
      // .finally(() => (this.isLoading = false));
    },
    async fetchEventData() {
      const date = [
        this.format(this.customFilter.date[0]),
        this.format(this.customFilter.date[1]),
      ];
      const params = {
        date,
        ...this.options,
        ...this.optionFilters,
      };
      this.isLoading = true;
      service
        .get(`vehicle/telematic-details-historical-playback-events`, params)
        .then((res) => {
          this.vehicle_status = res.body.vehicle_status;
          this.vehicle_model_type = res.body.vehicle_model_type;
          this.vehicle_model_body_type = res.body.vehicle_model_body_type;
          this.eventData = res.body.historical_playback_event_data;
        })
        .finally(() => (this.isLoading = false));
    },
    //
    clearData() {
      this.customFilter.date = [];

      this.streamData = [];
      this.path = [];
      this.eventData = [];
      this.isMap = false;
      setTimeout(() => {
        this.isMap = true;
      }, 100);
    },

    showError(err) {
      this.$store.dispatch("alerts/error", err?.message);
    },
  },
};
</script>

<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif
$primaryColor: #23BDAA
.messageDetail
  color: #4545c7 !important

.filterMenDropDown
  min-width: fit-content !important

.filterDropDown
  :deep input
    text-transform: capitalize !important

.SOC-Options
  display: flex
  gap: 1rem
  div:first-child
    flex-basis: 30%
  div:last-child
    flex-basis: 70%

.Latency-Options
  width: 350px
  display: flex
  gap: 1rem
  div:first-child, div:last-child
    flex-basis: 30%
  div:nth-child(2)
    flex-basis: 70%

:deep .v-toolbar__content
  align-items: baseline

.v-menu
  background: red

.semiPieChart
  width: 100%

.summaryRightCard
  .rightOverviewCard
    height: 48.30%
    margin-bottom: 4%
    &:last-child
      margin-bottom: 0
    .cardHeading
      width: 100%
.leftOverviewCard
  .overviewDetailsList
    list-style: none
    padding: 0
    margin: 0
    li
      margin-bottom: 1.45rem
      width: 100%
      border-bottom: 0
      .cardLabel
        font-family: $poppinMed
        color: #000
        font-size: 0.9375rem
        width: 100%
        padding: 0 0.75rem
        margin: 0 0 0.45rem 0
        display: flex
      .cardTextData
        color: #222
        display: inline-block
        font-weight: normal
        width: 50%

      ul
        margin: 0
        padding: 0
        width: 100% !important
        li
          margin-bottom: 0px
          width: 100% !important
          border-bottom: 1px solid #D9D9D9
          display: flex
          align-items: center
          min-height: 2.8rem
          padding: 0 0.75rem
          .cardLabel
            width: 50% !important
            margin:0
            font-family: $poppinReg
            font-size: 0.8125rem
            padding: 0
            margin: 0
            color: #767676
          .cardTextData
            width: 50% !important
            font-family: $poppinMed
            font-size: 0.8125rem
            color: #000
          &:nth-child(odd)
            background: #F5F5F5
.insideTabFiltersRow
    display: flex
    .top-filters
      :deep .v-text-field.v-text-field--enclosed .v-text-field__details
        display: none !important
.insideTabrefreshBtnWrap
    display: flex
    margin-left: auto


.tableContainer
  max-height: calc(100vh - 15px)
  margin-top: 0

  .v-data-table
    max-height: calc(100vh - 177px)
    min-height: calc(100vh - 177px)
    :deep td
      font-size: 0.875rem !important
      font-family: $poppinReg !important
      .v-icon.v-icon
        font-size:18px !important
      .imei-column
        font-family: $poppinReg !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important
      .open-link
        font-family: $poppinReg !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important
      .imei-column, .messageDetail, .open-link, .activeCustomer
        font-family: $poppinReg !important
        font-size: 0.875rem !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important

  .paginationFooterWrap
    display: flex
    flex-wrap: wrap
    align-items: center
    font-size: 0.75rem
    padding: 0 8px
    margin-right: 0 !important
    position: sticky
    z-index: 9
    width: 100%
    top: calc(100vh - 6vh)
    background: #EAECF0
    height: 40px
    .v-data-footer__select
      height: 40px
    .pagination
      width: 100%
      margin-bottom: 0 !important
      :deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot
        min-height: 29px !important
      :deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner
        margin-top: 0 !important
        height: 100%
        display: flex
        align-items: center
        width: 18px
      :deep .page-btn div span
        padding: 0.45rem 0.75rem
        font-size: 0.7rem

  :deep .v-data-table__wrapper
    position: relative

  :deep .v-data-table-header tr th
    background: #EAECF0 !important


  :deep .v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot
    min-height: 46px


  :deep .v-data-table-header tr th
    height: 40px !important

.text-blue-500
  color: #5D87FF
.text-orange-500
  color: #FFAE20
.text-green-500
  color: #13DEB9
.text-red-500
  color: #FA896B
.moreThan100
  background-color: #FDEDE8
  width: 100%
  display: inline-block
  text-align: center

.moreThan50
  background-color: #D7FFE9
  display: inline-block
  text-align: center


.lessThan50
  background-color: #ECF2FF
  display: inline-block
  text-align: center

:deep .top-filters .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-inner
  height: 42px


.tableContainer
  width: 100%

.historyplaybacktop
  display: flex
  width: 100%
  margin-bottom: 0.75rem

  .play-pause-container
    display: flex
    justify-content: flex-start
    margin-bottom: 0rem

  .slider-container
    display: flex
    margin-left: auto
    margin-top: 3px
    :deep .v-input__slot
      margin-bottom: 0 !important
    :deep .v-messages
      display: none !important
  .slider-label
    font-size: 0.875rem
    color: #000000
    margin-right: 8px
    margin-top: 5px
    font-family: $poppinMed
  .custom-slider
    width: 70px

  .v-slider
    height: 16px

  .v-slider__thumb
    width: 16px
    height: 16px
    background-color: #4285f4
  .v-slider__track
    height: 8px
    background-color: #e5eaf0

  .v-slider__track-fill
    background-color: #4285f4

.mapWrapper
  width: 100%
  display: flex
  img
    width: 100%

.v-sheet button.play-pause-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab)
  font-size: 0.875rem !important
  padding: 0 22px !important
  letter-spacing: 0 !important
  text-transform: capitalize
  min-height: 30px !important
  height: 34px
  margin: 0
  box-shadow: 0 0 0 !important
  color: #fff !important
  background: #20a390 !important

.historyplaybackInfo
  display: flex
  flex-wrap: wrap
  width: 100%
  flex-direction: row
  .v-card
    width: 100%
    box-shadow: 0 0 0
    display: flex
    flex-wrap: wrap
    padding: 0 !important
    margin: 1.25rem 0 0 0 !important
    .row
      display: flex
      flex-wrap: wrap
      flex-direction: column
      align-items: center
      margin: 0 0 0 0 !important
      width: 16.667%
      .col
        padding: 0 !important
        color: #767676
        font-family: $poppinReg

        .font-weight-bold
          color: #000
          font-family: $poppinMed
          margin-top: 0.25rem

.dateRange
  display: flex
  gap: 1rem

:deep .leaflet-popup-content
  width: 200px !important
  .title
    font-weight: 600 !important
    font-size: large !important
</style>
